exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ferienwohnung-1-47-qm-js": () => import("./../../../src/pages/ferienwohnung_1_47qm.js" /* webpackChunkName: "component---src-pages-ferienwohnung-1-47-qm-js" */),
  "component---src-pages-ferienwohnung-2-39-qm-js": () => import("./../../../src/pages/ferienwohnung_2_39qm.js" /* webpackChunkName: "component---src-pages-ferienwohnung-2-39-qm-js" */),
  "component---src-pages-ferienwohnung-3-61-qm-js": () => import("./../../../src/pages/ferienwohnung_3_61qm.js" /* webpackChunkName: "component---src-pages-ferienwohnung-3-61-qm-js" */),
  "component---src-pages-ferienwohnungen-js": () => import("./../../../src/pages/ferienwohnungen.js" /* webpackChunkName: "component---src-pages-ferienwohnungen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-region-js": () => import("./../../../src/pages/region.js" /* webpackChunkName: "component---src-pages-region-js" */),
  "component---src-pages-werfenweng-card-js": () => import("./../../../src/pages/werfenweng-card.js" /* webpackChunkName: "component---src-pages-werfenweng-card-js" */)
}

